import React from 'react';
import {
  Typography,
  Chip,
  IconButton,
  Tooltip
} from '@material-tailwind/react';
import { HiPencil } from "react-icons/hi2";
import { MdDelete } from "react-icons/md";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { IoPlay } from "react-icons/io5";
import { apiDeletePrompt, apiOnEnablePrompt, apiRunPrompt } from '@/shared/api/common';
import { showToast } from '@/components/UI/PrimaryToast';

interface Props {
  prompt: any;
  classes: string;
  onDelete: () => void;
  onEdit: () => void;
  onRun: () => void;
}
const PromptTableRow = ({prompt, classes, onDelete, onEdit, onRun}: Props) => {

  const onDeleteHandler = async() => {
    const res = await apiDeletePrompt({promptId: prompt._id});
    if(res?.success) {
      showToast("success", `Deleted Successfully!`);
      onDelete();
    }
  }

  const onEnableHandler = async() => {
    const res = await apiOnEnablePrompt({promptId: prompt._id, enabled: !prompt.enabled});
    if(res?.success) {
      showToast("success", `${res.data.enabled? 'Enabled' : 'Disabled'} Successfully!`);
      onDelete();
    }
  }

  // Add State
  const [openAddPage, setOpenAddPage] = React.useState(false);
  const handleOpenAddPage = () => {
    setOpenAddPage(!openAddPage);
  }

  // Response State
  const [openResponsePage, setOpenResponsePage] = React.useState(false);
  const handleOpenResponsePage = () => {
    setOpenResponsePage(!openResponsePage);
  }

  return (
    <tr className="even:bg-blue-gray-50/50">
      <td className={classes}>
        <div className='w-96'>
          <Typography placeholder
            variant="small"
            color="blue-gray"
            className="truncate font-normal"
          >
            {prompt.label}
          </Typography>
        </div>
      </td>
      <td className={classes}>
        <Typography placeholder
          variant="small"
          color="blue-gray"
          className="font-normal"
        >
          {prompt.channel}
        </Typography>
      </td>
      <td className={classes}>
        <div className="w-max">
          <Chip
            variant="ghost"
            size="sm"
            value={prompt.enabled ? "Enabled" : "Disabled"}
            color={prompt.enabled ? "green" : "blue-gray"}
          />
        </div>
      </td>
      <td className={classes}>
        <div className="w-max">
          <Chip
            variant="ghost"
            size="sm"
            value={prompt.isAutomation ? "✔︎" : "✘"}
            color={prompt.isAutomation ? "green" : "blue-gray"}
          />
        </div>
      </td>
      <td className={classes}>
        <Tooltip content="Edit">
          <IconButton placeholder variant="text" onClick={onEdit}>
            <HiPencil size={16} />
          </IconButton>
        </Tooltip>
        <Tooltip content="Delete">
          <IconButton placeholder variant="text" onClick={onDeleteHandler}>
            <MdDelete size={19} />
          </IconButton>
        </Tooltip>
        <Tooltip content={!prompt.enabled ? "Enable" : "Disable"}>
          <IconButton placeholder variant="text" onClick={onEnableHandler}>
            {!prompt.enabled ? <IoMdEye size={23} /> : <IoMdEyeOff size={23} />}
          </IconButton>
        </Tooltip>
        <Tooltip content="Run">
          <IconButton placeholder variant="text" onClick={()=>{
            onRun();
          }}>
            <div className='relative'>
            <IoPlay size={20} />
            </div>
          </IconButton>
        </Tooltip>
      </td>
    </tr>
  )
}

export default PromptTableRow