import { Spinner } from "@material-tailwind/react";

interface Props {
  size?: 'small' | 'medium' | 'large';
  color: any;
}

export const LoadingCircular = ({ size, color }: Props) => {
  return (
    <Spinner
      color={color}
      className={size === "small" ? "w-6 h-6" : "w-12 h-12"}
    />
  );
};
