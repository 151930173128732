import { metricParams } from '@/shared/utils/constants'
import { Select, Option } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'

interface Props {
  data?: string | null;
  setData?: (id: string) => void;
}

function MetricParamSelect({data, setData}: Props) {
  const [selectedOption, setSelectedOption] = useState("0");

  useEffect(() => {
    if(data) {
      setSelectedOption(metricParams.findIndex((metric: any) => metric.label == data).toString());
    } else {
      setSelectedOption("0");
      onChange(0);
    }
  }, [data]);

  const handleChange = (value: any) => {
    setSelectedOption(value);
    onChange(parseInt(value));
  };

  const onChange = (index: number) => {
    setData && setData(metricParams[index].label);
  }

  return (
    <div className="relative w-full">
      <Select placeholder
        label='Metric'
        value={selectedOption}
        onChange={handleChange}
        menuProps={{ className: "font-normal text-sm z-50" }}
      >
        {metricParams && metricParams.map((metric: any, index: number) => (
          <Option key={index} value={index.toString()}>{metric.label}</Option>
        ))}
      </Select>
    </div>
  )
}

export default MetricParamSelect