import TextArea from "@/components/UI/TextArea";
import { ChartDate, IChartData } from "@/types/props";
import { BarChart } from "@shopify/polaris-viz";
import { useEffect, useState } from "react";

interface Props {
  data: any;
}

const mock =
  [
    {
      "name": "Breakfast",
      "data": [
        {
          "key": "Monday",
          "value": 3
        },
        {
          "key": "Tuesday",
          "value": -7
        },
        {
          "key": "Wednesday",
          "value": -7
        },
        {
          "key": "Thursday",
          "value": -8
        },
        {
          "key": "Friday",
          "value": 45
        },
        {
          "key": "Saturday",
          "value": 0
        },
        {
          "key": "Sunday",
          "value": 0.1
        }
      ]
    },
    {
      "name": "Lunch",
      "data": [
        {
          "key": "Monday",
          "value": 4
        },
        {
          "key": "Tuesday",
          "value": 0
        },
        {
          "key": "Wednesday",
          "value": -10
        },
        {
          "key": "Thursday",
          "value": 15
        },
        {
          "key": "Friday",
          "value": 8
        },
        {
          "key": "Saturday",
          "value": 45
        },
        {
          "key": "Sunday",
          "value": 0.1
        }
      ]
    },
    {
      "name": "Dinner",
      "data": [
        {
          "key": "Monday",
          "value": 7
        },
        {
          "key": "Tuesday",
          "value": 0
        },
        {
          "key": "Wednesday",
          "value": -15
        },
        {
          "key": "Thursday",
          "value": -12
        },
        {
          "key": "Friday",
          "value": 45
        },
        {
          "key": "Saturday",
          "value": 5
        },
        {
          "key": "Sunday",
          "value": 0.1
        }
      ]
    }
  ]

function ColumnChart({ data }: Props) {
  const [dt, setDt] = useState<IChartData[]>([]);
  const [isGoogle, setIsGoogle] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  useEffect(() => {
    if (data) {
      // console.log(data)
      if (data?.url.includes('google')) {
        setIsShow(true);
        setIsGoogle(true);
        let chartData: IChartData = { data: [], name: data?.name };
        let chartDataArray: IChartData[] = [];
        data?.data.forEach((item: any) => {
          chartData.data.push({ key: item.productTitle, value: item[data?.metric] });
        });
        chartDataArray.push(chartData);
        setDt(chartDataArray);
      }
      else if (data?.url == "/shopify/api/segment-customers") {
        setIsShow(true);
        let chartDataArray: IChartData[] = [];
        let chartData_top: IChartData = { data: [], name: "googleCustomers_top" };
        let chartData_bottom: IChartData = { data: [], name: "googleCustomers_bottom" };
        data?.data["googleCustomers_top"].forEach((item: any) => {
          chartData_top.data.push({ key: item.first_name, value: item.total_spent });
        });
        chartDataArray.push(chartData_top);
        data?.data["googleCustomers_bottom"].forEach((item: any) => {
          chartData_bottom.data.push({ key: item.first_name, value: item.total_spent });
        });
        chartDataArray.push(chartData_bottom);
        setDt(chartDataArray);
      }
      else {
        setIsShow(false);
      }
    }
  }, [data])

  return (
    <>
      {isShow && (
        <div className="flex flex-col">
          <p className="text-base font-medium ml-2 mb-2">{data?.name}</p>
          <BarChart
            data={dt}
          />
        </div>
      )}
    </>
  )
}

export default ColumnChart