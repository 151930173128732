import React, { useEffect } from "react";
import {
  Dialog,
  IconButton,
  Typography,
  DialogBody,
  DialogHeader,
} from "@material-tailwind/react";
import { IoCloseOutline } from "react-icons/io5";
import { PiStarFourThin } from "react-icons/pi";
import Scrollbars from "react-custom-scrollbars-2";
import { DefaultTable } from "./defaultTable";
import PieChart from "./pieChart";
import LineCharts from "./lineChart";
import ColumnChart from "./columnChart";

interface Props {
  open: boolean;
  handleOpen: () => void;
  data: any;
}

function ResponseResultPrompt({ open, handleOpen, data }: Props) {

  useEffect(() => {
    if(data) {
      console.log(data);
    }
  }, [data])

  const formatResponse = (text: any) => {
    if (!text) return;
    return text.split('\n').map((item: any, index: number) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <>
      <Dialog placeholder size="xxl" open={open} handler={() => { }} className="p-8">
        <DialogHeader placeholder className="relative m-0 block">
          <Typography placeholder variant="h4" color="blue-gray">
            Result
          </Typography>
          <IconButton placeholder
            size="sm"
            variant="text"
            className="!absolute right-3.5 top-3.5"
            onClick={handleOpen}
          >
            <IoCloseOutline className="h-4 w-4 stroke-2" />
          </IconButton>
        </DialogHeader>
        <DialogBody placeholder className="space-y-4 h-full pb-6 text-black">
          <Scrollbars className="text-xs text-left h-5/6">
            <div className='flex gap-3'>
              <div className='w-6 justify-center'>
                <PiStarFourThin size={20} />
              </div>
              <div className='flex-1 flex flex-col gap-4'>
                <p className="text-base font-medium">{formatResponse(data.response)}</p>
                <div className="mt-4 grid grid-cols-1 gap-4">
                  {data.results.map((item: any, index: number) => (
                    item.chart.toLowerCase() == 'table' ? <DefaultTable key={index} data={item} /> :
                    item.chart.toLowerCase() == 'line' ? <LineCharts key={index} data={item}/> :
                    item.chart.toLowerCase() == 'pie' ? <PieChart key={index} data={item}/>:
                    item.chart.toLowerCase() == 'column' ? <ColumnChart key={index} data={item}/> : <></>
                  ))}
                </div>
              </div>
            </div>
          </Scrollbars>
        </DialogBody>
      </Dialog>
    </>
  )
}

export default ResponseResultPrompt
