import React, { useState } from "react";
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Radio,
} from "@material-tailwind/react";
import { IoCloseOutline } from "react-icons/io5";
import InputBox from "@/components/UI/InputBox";
import MetricURLSelect from "./metricURLs";
import MetricChartSelect from "./metricCharts";
import ResponseResultPrompt from "../Prompt/responseResultPrompt";
import RunMetric from "./runMetric";
import { apiAddNewMetric } from "@/shared/api/metric";
import { showToast } from "@/components/UI/PrimaryToast";
import TextArea from "@/components/UI/TextArea";

interface Props {
  open: boolean;
  handleOpen: () => void;
}

function addMetric({ open, handleOpen }: Props) {
  const [name, setName] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [chartType, setChartType] = useState<string>("");
  const [requestType, setRequestType] = useState<string>("GET");
  const [description, setDescription] = React.useState("");

  const onRegister = async () => {
    if (name == "" || url == "" || chartType == "" || description == '') {
      showToast("error", "Please fill all fields");
      return;
    }
    const res = await apiAddNewMetric({
      name: name,
      description,
      url: url,
      request: requestType,
      chart: chartType,
      params: getParams(url),
    });

    if (res?.success) {
      showToast("success", `Database Added Successfully!`);
      handleOpen();
    }
  };

  const getParams = (url: string) => {
    const params_google = ["startDate", "endDate", "metric"];
    const params_facebook = ["startDate", "endDate", "metric"];
    const params_shopify = ["shopName", "startDate", "endDate"];
    if (url == "/google/api/top-perform-products-metric") {
      return params_google;
    } else if (url == "/google/api/top-perform-products-collection-metric") {
      return params_google;
    } else if (url == "/google/api/top-perform-keywords-metric") {
      return params_google;
    } else if (url == "/google/api/low-convert-search-terms-metric") {
      return params_google;
    } else if (url == "/google/api/top-perform-devices-locations-metric") {
      return params_google;
    } else if (url == "/google/api/top-worst-perform-location-metric") {
      return params_google;
    } else if (url == "/google/api/top-three-perform-ads-metric") {
      return params_google;
    } else if (url == "/shopify/api/best-selling-products") {
      return params_shopify;
    } else if (url == "/shopify/api/top-revenue-products") {
      return params_shopify;
    } else if (url == "/shopify/api/avg-time-between-purchases") {
      return ["shopName"];
    } else if (url == "/shopify/api/segment-customers") {
      return ["shopName"];
    } else if (url == "/shopify/api/top-selling-collections") {
      return params_shopify;
    } else if (url == "/shopify/api/frequently-bought-together") {
      return ["shopName"];
    } else if (url == "/shopify/api/low-stock-products") {
      return params_shopify;
    } else if (url == "/facebook/api/ads/daily_performance") {
      return params_facebook;
    } else if (url == "/facebook/api/ads/performance_by_devices") {
      return params_facebook;
    } else if (url == "/facebook/api/ads/performance_by_age_gender") {
      return params_facebook;
    } else if (url == "/facebook/api/ads/performance_by_range") {
      return params_facebook;
    } else if (url == "/facebook/api/ads/performance_by_platform_position") {
      return params_facebook;
    }
    return [];
  };
  // Edit State
  const [openRunPage, setOpenRunPage] = React.useState(false);
  const handleOpenRunPage = () => {
    setOpenRunPage(!openRunPage);
  };

  // Response State
  const [openResponsePage, setOpenResponsePage] = React.useState(false);
  const handleOpenResponsePage = () => {
    setOpenResponsePage(!openResponsePage);
  };

  return (
    <>
      <Dialog
        placeholder
        size="lg"
        open={open}
        handler={() => {}}
        className="p-4"
      >
        <DialogHeader placeholder className="relative m-0 block">
          <Typography placeholder variant="h4" color="blue-gray">
            Create New Database
          </Typography>
          <IconButton
            placeholder
            size="sm"
            variant="text"
            className="!absolute right-3.5 top-3.5"
            onClick={handleOpen}
          >
            <IoCloseOutline className="h-4 w-4 stroke-2" />
          </IconButton>
        </DialogHeader>
        <DialogBody placeholder className="space-y-4 pb-6">
          <div className="flex flex-col gap-4">
            <InputBox
              label="Name"
              placeholder="name"
              value={name}
              onChangeHandler={(e) => {
                setName(e.target.value);
              }}
            />
            <div className="flex gap-10">
              <Radio
                crossOrigin
                name="type"
                checked={requestType === "GET"}
                onChange={() => setRequestType("GET")}
                label={
                  <Typography placeholder className="text-sm font-medium">
                    GET
                  </Typography>
                }
              />
              <Radio
                crossOrigin
                name="type"
                checked={requestType === "POST"}
                onChange={() => setRequestType("POST")}
                label={
                  <Typography placeholder className="text-sm font-medium">
                    POST
                  </Typography>
                }
              />
            </div>
            <MetricURLSelect data={null} setData={setUrl} />
            <MetricChartSelect data={null} setData={setChartType} />
            <TextArea
              label="Description"
              value={description}
              placeholder="descrption"
              onChangeHandler={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </DialogBody>
        <DialogFooter placeholder>
          <Button
            placeholder
            size="md"
            className="mr-3"
            onClick={handleOpenRunPage}
          >
            Run
          </Button>
          <Button placeholder size="md" onClick={onRegister}>
            Register
          </Button>
        </DialogFooter>
      </Dialog>
      <RunMetric open={openRunPage} handleOpen={handleOpenRunPage} url={url} />
      {/* <ResponseResultPrompt open={openResponsePage} handleOpen={handleOpenResponsePage} /> */}
    </>
  );
}

export default addMetric;
