import React, { useCallback, useEffect, useState } from 'react';
import SearchInput from '@/components/UI/SearchInput';
import {
  Card,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  IconButton,
  Tooltip
} from '@material-tailwind/react';
import { LuPlus } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import AddMetric from './addMetric';
import { useInfiniteMetrics } from '@/hooks/useInfiniteQueries';
import _ from 'lodash';
import { apiDeleteMetric } from '@/shared/api/metric';
import { showToast } from '@/components/UI/PrimaryToast';

const TABLE_HEAD = ["Name", "URL", "Request", ""];

const MetricPage = () => {
  const [query, setQuery] = useState<string>("");
  const [metricData, setMetricData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    data,
    error,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    isFetched,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    refetch,
  } = useInfiniteMetrics(query, 10);

  const debouncedRefetch = useCallback(
    _.debounce(() => {
      refetch();
    }, 300),
    [refetch]
  );

  useEffect(() => {
    setCurrentPage(1);
    debouncedRefetch();
    // clean-up function to cancel debounce on unmount
    return () => {
      debouncedRefetch.cancel();
    };
  }, [query, debouncedRefetch]);

  useEffect(() => {
    if (data) {
      const totalItems = data.pages[0].total;
      const totalPages = Math.ceil(totalItems / 10);
      setTotalPages(totalPages);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const pageData = data.pages[currentPage - 1]?.data || [];
      setMetricData(pageData); // Set only the data of the current page
    }
  }, [data, currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
      // Call fetchNextPage only if the last page has not been reached.
      if (hasNextPage && currentPage + 1 <= totalPages) {
        fetchNextPage();
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      if (hasPreviousPage) {
        fetchPreviousPage();
      }
    }
  };

  const onDelete = async(metric: any) => {
    const res = await apiDeleteMetric({metricId: metric._id});
    if(res?.success) {
      showToast("success", `Deleted Successfully!`);
      refetch();
    }
  }

  // Edit State
  const [openEditPage, setOpenEditPage] = React.useState(false);
  const handleOpenEditPage = () => {
    setOpenEditPage(!openEditPage);
  }
  // Add State
  const [openAddPage, setOpenAddPage] = React.useState(false);
  const handleOpenAddPage = () => {
    setOpenAddPage(!openAddPage);
  }

  // Response State
  const [openResponsePage, setOpenResponsePage] = React.useState(false);
  const handleOpenResponsePage = () => {
    setOpenResponsePage(!openResponsePage);
  }

  return (
    <div className="mx-auto max-w-4xl relative h-full text-black flex flex-col pb-2 pt-10">
      <Card placeholder className="h-full w-full">
        <div className="rounded-none p-4">
          <p className='text-lg font-semibold mb-4'>All Database</p>
          <div className='flex items-center justify-between'>
            <div className='flex items-center flex-1 gap-4'>
              <div className='w-2/5'>
                <SearchInput
                  label={'Search'}
                  type="text"
                  value={query}
                  color="black"
                  onChangeHandler={(e) => {
                    setQuery(e.target.value);
                  }}
                />
              </div>
            </div>
            <Button placeholder className="flex items-center gap-2 rounded-md h-[38px]" size="sm" variant='outlined' onClick={handleOpenAddPage}>
              <LuPlus size={16} />
              <p className="text-xs font-normal capitalize">Create Database</p>
            </Button>
          </div>
        </div>
        <CardBody placeholder className="overflow-auto px-4 py-0">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={head}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography placeholder
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                    >
                      {head}{" "}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {metricData.map(
                (metric: any, index: number) => {
                  const isLast = index === metricData.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";

                  return (
                    <tr key={index} className="even:bg-blue-gray-50/50">
                      <td className={classes}>
                        <div className='w-80'>
                          <Typography placeholder
                            variant="small"
                            color="blue-gray"
                            className="truncate font-normal"
                          >
                            {metric.name}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <Typography placeholder
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {metric.url}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div className="w-max">
                          <Chip
                            variant="ghost"
                            size="sm"
                            value={metric.request}
                            color={metric.request === "GET" ? "green" : "blue-gray"}
                          />
                        </div>
                      </td>
                      <td className={classes}>
                        <Tooltip content="Delete">
                          <IconButton placeholder variant="text" onClick={()=>{onDelete(metric)}}>
                            <MdDelete size={19} />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        </CardBody>
        <CardFooter placeholder className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography placeholder variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPages}
          </Typography>
          <div className="flex gap-2">
            {isFetched && metricData.length > 0 &&
              <>
                <Button placeholder variant="outlined" size="sm" onClick={handlePreviousPage} disabled={currentPage === 1 || isFetchingPreviousPage}>
                  Previous
                </Button>
                <Button placeholder variant="outlined" size="sm" onClick={handleNextPage} disabled={currentPage === totalPages || isFetchingNextPage}>
                  Next
                </Button>
              </>}
          </div>
        </CardFooter>
      </Card>
      <AddMetric open={openAddPage} handleOpen={() => {
        refetch();
        handleOpenAddPage();
      }} />
    </div>
  )
}

export default MetricPage