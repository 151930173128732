import { CiChat1 } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import appAction from "@/redux/app/action";
import { PiDotsThreeBold } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RootState } from "@/redux/interfaces";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { apiDeleteRecentPrompt, apiGetRecentPrompts } from "@/shared/api/common";

interface Props {
  data: any;
}

function PromptRecent({ data }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDeletePrompt } = useSelector((state: RootState) => state.app);

  const onClickHandler = async() => {
    const res = await apiGetRecentPrompts({ promptId: data.promptId });
    if(res?.success){
      if(data.channel === "google" || data.channel === 'meta')
        navigate(`/ads/${data.channel}`);
      else
        navigate(`/`);
      setTimeout(() => {
        dispatch(appAction.onUpdateResponsePage(true));
        dispatch(appAction.onUpdatePromptRequestData({ promptId: data.promptId }));
        // dispatch(appAction.onUpdatePromptResponseData(res.data));
      }, 50);
    }
  };
  const onDeleteHandler = async() => {
    const res= await apiDeleteRecentPrompt({promptId: data.promptId});
    if(res?.success){
      dispatch(appAction.onDeletePrompt(!isDeletePrompt));
    }
  }
  return (
    <div className="flex items-center py-2 pl-1.5 cursor-pointer">
      <div>
        <CiChat1 size={13} />
      </div>
      <p onClick={onClickHandler} className="ml-2 line-clamp-1 text-[12px] font-semibold text-gray-700 hover:text-blue-600">{data.label}</p>
      {/* <p onClick={onClickHandler} className="text-[12px] text-gray-700 hover:text-blue-600">{data.createdAt}</p> */}
      <Menu placement="bottom-start">
        <MenuHandler>
          <div className=""><PiDotsThreeBold size={15}/></div>
        </MenuHandler>
        <MenuList placeholder>
          <MenuItem placeholder>
            <div className="flex items-center gap-2.5" onClick={onDeleteHandler}>
              <RiDeleteBin6Line color="red" size={16} /> <span>Delete</span>
            </div>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}

export default PromptRecent;
