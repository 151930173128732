import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Input
} from "@material-tailwind/react";
import { IoCloseOutline, IoPlay } from "react-icons/io5";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/interfaces";
import MetricParamSelect from "../Metric/metricParamSelect";
import { apiRunPrompt } from "@/shared/api/common";
import { toISODateString } from "@/shared/utils/utils";
import { showToast } from "@/components/UI/PrimaryToast";
import { LoadingCircular } from "@/components/UI/LoadingCircular";

interface Props {
  open: boolean;
  prompt: any;
  handleOpen: () => void;
  onRun: (params: any) => void;
}

function RunPrompt({ open, prompt, handleOpen, onRun }: Props) {
  const { shopify } = useSelector((state: RootState) => state.app);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [metricParam, setMetricParam] = useState<string>("ROAS");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onRunPrompt = async () => {
    if (isLoading) return;
    if (!startDate || !endDate || !metricParam) return;
    const start_date = toISODateString(startDate);
    const end_date = toISODateString(endDate);
    setIsLoading(true);
    const res = await apiRunPrompt({
      promptId: prompt._id,
      startDate: start_date,
      endDate: end_date,
      metric: metricParam,
      prompt_body: prompt.prompt_body,
      isSendOpenAI: prompt.isSendOpenAI,
      isAutomation: prompt.isAutomation
    });
    setIsLoading(false);
    if (res?.success) {
      showToast("success", `Prompt Run Successfully!`);
      onRun(res.data);
      handleOpen();
    }
  };

  return (
    <>
      <Dialog placeholder size="xl" open={open} handler={() => {}} className="p-4">
        <DialogHeader placeholder className="relative m-0 block">
          <Typography placeholder variant="h4" color="blue-gray">
            Database Parameters
          </Typography>
          <IconButton placeholder size="sm" variant="text" className="!absolute right-3.5 top-3.5" onClick={()=>{
            if(isLoading) return;
            handleOpen();
          }}>
            <IoCloseOutline className="h-4 w-4 stroke-2" />
          </IconButton>
        </DialogHeader>
        <DialogBody placeholder className="space-y-4 pb-6">
          <div className="flex gap-4">
            <div className="w-full flex flex-col gap-6">
              <Input crossOrigin label="Store Name" type="text" value={shopify?.shopName!} />
              <Input
                crossOrigin
                label="Start-Date"
                type="date"
                value={startDate.toISOString().split("T")[0]}
                onChange={(e) => {
                  setStartDate(new Date(e.target.value));
                }}
              />
              <Input
                crossOrigin
                label="End-Date"
                type="date"
                value={endDate.toISOString().split("T")[0]}
                onChange={(e) => {
                  setEndDate(new Date(e.target.value));
                }}
              />
              <MetricParamSelect data={metricParam} setData={setMetricParam} />
            </div>
          </div>
        </DialogBody>
        <DialogFooter placeholder>
          <Button
            placeholder
            className="flex items-center gap-3 mr-2"
            color="blue"
            onClick={() => {
              onRunPrompt();
            }}
          >
            <IoPlay size="16" /> Run
            {isLoading && <LoadingCircular color="red" size="small" />}
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default RunPrompt;
