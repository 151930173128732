import { Input, Typography } from "@material-tailwind/react";

interface Props {
  label?: string;
  placeholder?: string;
  value?: string;
  onChangeHandler?: (e: any) => void;
}

export default function InputBox({ label, placeholder, value, onChangeHandler }: Props) {
  return (
    <div className="w-full">
      <Typography placeholder
        variant="small"
        color="blue-gray"
        className="mb-2 text-left font-medium"
      >
        {label}
      </Typography>
      <Input crossOrigin placeholder={placeholder} value={value}
        className="!border !border-blue-gray-200/90 text-sm bg-white text-gray-900 placeholder:opacity-60 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
        labelProps={{
          className: "hidden",
        }}
        containerProps={{ className: "min-w-[100px]" }}
        onChange={onChangeHandler}
      />
    </div>
  );
}