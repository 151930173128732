import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogBody,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import { IoCloseOutline } from "react-icons/io5";
import MetricsSelect from "@/pages/Prompt/metrics";
import ChannelSelect from "@/components/UI/ChannelSelect";
import { Channel } from "@/types/enum";
import TextArea from "@/components/UI/TextArea";
import InputBox from "@/components/UI/InputBox";
import { apiAddNewPrompt } from "@/shared/api/common";
import { showToast } from "@/components/UI/PrimaryToast";

interface Props {
  open: boolean;
  handleOpen: () => void;
}

function AddPrompt({ open, handleOpen }: Props) {
  const [label, setLabel] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [question, setQuestion] = useState<string>('');
  const [channelType, setChannelType] = useState<Channel>(Channel.AllChannels);
  const [selectedMetric, setSelectedMetric] = useState<string>('');

  const onAddPrompt = async () => {
    if(!label || !text || !channelType || !question) {
      showToast("error", `Please fill all the fields!`);
      return;
    }
    if(!selectedMetric) {
      showToast("error", `Please select database!`);
      return;
    }
    const res = await apiAddNewPrompt({
      label: label, prompt: text, channel: channelType, prompt_body: [
        { question: question, required_metrics: [{metric: selectedMetric, isShowResult: true}] }
      ]
    });

    if (res?.success) {
      showToast("success", `Added Successfully!`);
      handleOpen();
    }
  }

  return (
    <>
      <Dialog placeholder size="xl" open={open} handler={() => { }} className="p-4">
        <DialogHeader placeholder className="relative m-0 block">
          <Typography placeholder variant="h4" color="blue-gray">
            Add New Prompt
          </Typography>
          <IconButton placeholder
            size="sm"
            variant="text"
            className="!absolute right-3.5 top-3.5"
            onClick={handleOpen}
          >
            <IoCloseOutline className="h-4 w-4 stroke-2" />
          </IconButton>
        </DialogHeader>
        <DialogBody placeholder className="h-[42rem] overflow-scroll !px-4">
          <div className="flex gap-4 mb-4">
            <InputBox label="User Prompt" placeholder="user prompt" value={label}
              onChangeHandler={(e) => {
                setLabel(e.target.value);
              }}
            />
            <div style={{ zIndex: 999 }}>
              <Typography placeholder
                variant="small"
                color="blue-gray"
                className="mb-2 text-left font-medium"
              >
                Channel
              </Typography>
              <ChannelSelect channelType={channelType} setChannelType={setChannelType} isBorder />
            </div>
          </div>
          <TextArea label="User Question" value={text} placeholder="user question" setValue={setText} isTag
            onChangeHandler={(e) => {
              setText(e.target.value);
            }}
          />
          <TextArea label="GPT Prompt" value={question} placeholder="gpt prompt" row={12}
            maxLength={Number(process.env.REACT_APP_OPENAI_TOKEN_LENGTH) || 4096}
            onChangeHandler={(e) => {
              setQuestion(e.target.value);
            }}
          />
          <MetricsSelect data={null} setData={setSelectedMetric} />
        </DialogBody>
        <DialogFooter placeholder>
          <Button placeholder size="md" onClick={onAddPrompt}>
            Add
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default AddPrompt