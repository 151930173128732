import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Checkbox
} from "@material-tailwind/react";
import { IoCloseOutline } from "react-icons/io5";
import { LuPlus, LuMinus } from "react-icons/lu";
import MetricsSelect from "@/pages/Prompt/metrics";
import TextArea from "@/components/UI/TextArea";
import { MdDelete } from "react-icons/md";

interface Props {
  open: boolean;
  handleOpen: () => void;
  data: any;
  onEditQuestion: (question: any) => void;
}

interface MetricItemProps {
  metric: string;
  isShowResult: boolean;
}

function EditQuestion({ open, handleOpen, data, onEditQuestion }: Props) {
  const [question, setQuestion] = React.useState(data?.question);
  const [metrics, setMetrics] = React.useState<MetricItemProps[]>(data?.required_metrics);

  useEffect(() => {
    if(data) {
      setQuestion(data?.question);
      setMetrics(data?.required_metrics);
    }
  }, [data]);

  const onAddMetric = () => {
    setMetrics((prev = []) => [...prev, { metric: "", isShowResult: true }]);
  };

  const onDeleteMetric = (index: number) => {
    if (metrics && metrics.length <= 1) return;
    setMetrics((prev = []) => prev.filter((_, i) => i !== index));
  };

  const onSelectMetric = (index: number, metric: MetricItemProps) => {
    if(!metric) return;
    setMetrics((prev = []) => prev.map((item, i) => (i === index ? metric : item)));
  }

  const onSave = () => {
    onEditQuestion({ question, required_metrics: metrics });
    handleOpen();
  }

  return (
    <Dialog placeholder size="md" open={open} handler={() => {}} className="p-4">
      <DialogHeader placeholder className="relative m-0 block">
        <Typography placeholder variant="h4" color="blue-gray">
          Edit GPT Prompt
        </Typography>
        <IconButton placeholder size="sm" variant="text" className="!absolute right-3.5 top-3.5" onClick={()=>{
          handleOpen();
        }}>
          <IoCloseOutline className="h-4 w-4 stroke-2" />
        </IconButton>
      </DialogHeader>
      <DialogBody placeholder className="space-y-4 pb-6 h-[42rem] overflow-scroll">
        <TextArea
          label="Question"
          value={question}
          placeholder="question"
          maxLength={Number(process.env.REACT_APP_OPENAI_TOKEN_LENGTH) || 4096}
          row={12}
          onChangeHandler={(e) => {
            setQuestion(e.target.value);
          }}
        />
        <div className="flex justify-between items-center">
          <Typography placeholder variant="small" color="blue-gray" className="mb-2 text-left font-medium">
            {"Databases"}
          </Typography>
          <Button
            placeholder
            className="flex items-center gap-2 rounded-md"
            size="sm"
            variant="outlined"
            onClick={onAddMetric}
          >
            <LuPlus size={16} />
            <p className="text-xs font-normal capitalize">Add Database</p>
          </Button>
        </div>
        <div>
          {metrics && metrics.map((item: MetricItemProps, index: number) => (
            <MetricItem
              key={index}
              index={index}
              data={item}
              onDeleteMetric={onDeleteMetric}
              onSelectMetric={onSelectMetric}
            />
          ))}
        </div>
      </DialogBody>
      <DialogFooter placeholder>
        <Button placeholder className="ml-3" onClick={onSave}>
          Add
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default EditQuestion;

export const MetricItem = ({
  index,
  data,
  onDeleteMetric,
  onSelectMetric
}: {
  index: number;
  data: MetricItemProps;
  onDeleteMetric: (index: number) => void;
  onSelectMetric: (index: number, metric: MetricItemProps) => void;
}) => {
  const [selectedMetric, setSelectedMetric] = useState<string>(data?.metric);
  const [isShowResult, setIsShowResult] = useState<boolean>(data?.isShowResult);

  useEffect(() => {
    onSelectMetric(index, {metric: selectedMetric, isShowResult: isShowResult});
  }, [selectedMetric, isShowResult]);

  return (
    <div className="flex items-center justify-center gap-1 mb-3">
      <Checkbox
        crossOrigin
        checked={isShowResult}
        onChange={() => setIsShowResult(!isShowResult)}
        label={
          <Typography placeholder color="blue-gray" className="text-xs font-normal">
            Show Result?
          </Typography>
        }
      />
      <MetricsSelect data={selectedMetric} setData={setSelectedMetric} />
      <IconButton
        placeholder
        variant="text"
        onClick={() => {
          onDeleteMetric(index);
        }}
      >
        <MdDelete size={19} />
      </IconButton>
    </div>
  );
};
