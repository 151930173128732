import TextArea from "@/components/UI/TextArea";
import { IChartData } from "@/types/props";
import { LineChart } from "@shopify/polaris-viz";
import { useEffect, useState } from "react";

interface Props {
  data: any;
}

function LineCharts({ data }: Props) {
  const [dt, setDt] = useState<IChartData[]>([]);
  const [isShow, setIsShow] = useState<boolean>(false);

  useEffect(() => {
    if(data){
      if (data?.url.includes('google')) {
        setIsShow(true);
        let chartData : IChartData = { data: [], name: data?.name };
        let chartDataArray: IChartData[] = [];
        data?.data.forEach((item: any) => {
          chartData.data.push({ key: item.productTitle, value: item[data?.metric] });
        });
        chartDataArray.push(chartData);
        setDt(chartDataArray);
      }
      else if (data?.url == "/shopify/api/segment-customers") {
        setIsShow(true);
        let chartDataArray: IChartData[] = [];
        let chartData_top: IChartData = { data: [], name: "googleCustomers_top" };
        let chartData_bottom: IChartData = { data: [], name: "googleCustomers_bottom" };
        data?.data["googleCustomers_top"].forEach((item: any) => {
          chartData_top.data.push({ key: item.first_name, value: item.total_spent });
        });
        chartDataArray.push(chartData_top);
        data?.data["googleCustomers_bottom"].forEach((item: any) => {
          chartData_bottom.data.push({ key: item.first_name, value: item.total_spent });
        });
        chartDataArray.push(chartData_bottom);
        setDt(chartDataArray);
      }
      else {
        setIsShow(false);
      }
    }
  }, [data])

  return (
    <>
    {isShow && (
      <div className="flex flex-col">
        <p className="text-base font-medium ml-2 mb-2">{data?.name}</p>
        <LineChart
          xAxisOptions={{
            labelFormatter: (x) => {
              return `${x}`
            }
          }}
          yAxisOptions={{
            labelFormatter: (y) => {
              return `${y}`
            }
          }}
          data={dt ?? SHARK_SPECIES_GROWTH}
        />
      </div>
    )}
    </>
  )
}

export default LineCharts

export const SHARK_SPECIES_GROWTH = [
  {
    name: 'Last',
    data: [
      {
        key: '2024-10-01',
        value: 80,
      },
      {
        key: '2024-10-05',
        value: 170,
      },
      {
        key: '2024-10-10',
        value: 210,
      },
      {
        key: '2024-10-15',
        value: 240,
      },
    ],
  },
  {
    name: 'Current',
    data: [
      {
        key: '2024-10-01',
        value: 80,
      },
      {
        key: '2024-10-05',
        value: 180,
      },
      {
        key: '2024-10-10',
        value: 250,
      },
      {
        key: '2024-10-15',
        value: 350,
      },
    ],
  },
];