export const defaultValue = "Hello ATMK!";

export const client_type_options = [{ value: "Brand" }, { value: "Agency" }];

export const customer_role_options = [
  { value: "Agency Owner" },
  { value: "Brand Owner" },
  { value: "CMO" },
  { value: "Marketing Manager" },
  { value: "Retention Manager" },
  { value: "Consultant" },
  { value: "Operations" },
  { value: "Finance" },
];

export const channels = [
  { label: "All Channels" },
  { label: "Meta Ads" },
  { label: "Google Ads" },
  { label: "Shopify" },
  { label: "Email" },
];

export const mockMetrics = [
  { name: "Top-performing products [Google Ads]" },
  { name: "Top-performing keywords [Google Ads]" },
  { name: "Top-performing Devices & Locations [Google Ads]" },
  { name: "List of low-converting search terms [Google Ads]" },
  {
    name: "5 best performing Locations and 5 worst performing Locations [Google Ads]",
  },
  { name: "What are the 3 best performing Ads [Google Ads]" },
];

export const metricURLs = [
  { label: "/google/api/top-perform-products-metric" },
  { label: "/google/api/top-perform-products-collection-metric" },
  { label: "/google/api/top-perform-keywords-metric" },
  { label: "/google/api/low-convert-search-terms-metric" },
  { label: "/google/api/top-perform-devices-locations-metric" },
  { label: "/google/api/top-worst-perform-location-metric" },
  { label: "/google/api/top-three-perform-ads-metric" },
  { label: "/shopify/api/best-selling-products" },
  { label: "/shopify/api/top-revenue-products" },
  { label: "/shopify/api/avg-time-between-purchases" },
  { label: "/shopify/api/segment-customers" },
  { label: "/shopify/api/top-selling-collections" },
  { label: "/shopify/api/frequently-bought-together" },
  { label: "/shopify/api/low-stock-products" },
  { label: "/facebook/api/ads/daily_performance" },
  { label: "/facebook/api/ads/performance_by_devices" },
  { label: "/facebook/api/ads/performance_by_age_gender" },
  { label: "/facebook/api/ads/performance_by_range" },
  { label: "/facebook/api/ads/performance_by_platform_position" },
];

export const metricCharts = [
  { label: "Table" },
  { label: "Line" },
  { label: "Pie" },
  { label: "Column" },
];

export const metricParams = [
  { label: "ROAS" },
  { label: "CTR" },
  { label: "CPA" },
  { label: "CONVERSIONS" },
  { label: "CONVERSIONS_VALUE" },
];

export const testJsonData = {
  success: true,
  data: [
    {
      productId: "7361222049897",
      totalQuantity: 8,
      totalRevenue: 160,
      variantSales: {
        "41697136771177": {
          totalQuantity: 8,
          totalRevenue: 160,
        },
      },
    },
    {
      productId: "7361224900713",
      totalQuantity: 7,
      totalRevenue: 350,
      variantSales: {
        "41697145061481": {
          totalQuantity: 7,
          totalRevenue: 350,
        },
      },
    },
    {
      productId: "7361116045417",
      totalQuantity: 2,
      totalRevenue: 10,
      variantSales: {
        "41696896483433": {
          totalQuantity: 2,
          totalRevenue: 10,
        },
      },
    },
  ],
};
