import HttpClient from "./apiClient";

export async function apiGetMetaKpisData(params: {
  startDate: string;
  endDate: string;
}) {
  try {
    const response = await HttpClient.getWithToken("/facebook/api/ads/kpi", {
      ...params,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetMetaPerformancesDataByDate(params: {
  startDate: string;
  endDate: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      "/facebook/api/ads/daily_performance",
      {
        ...params,
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetMetaPerformancesByDevices(params: {
  startDate: string;
  endDate: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      "/facebook/api/ads/performance_by_devices",
      {
        ...params,
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetMetaPerformancesByAgeGender(params: {
  startDate: string;
  endDate: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      "/facebook/api/ads/performance_by_age_gender",
      {
        ...params,
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetMetaPerformancesByRange(params: {
  startDate: string;
  endDate: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      "/facebook/api/ads/performance_by_range",
      {
        ...params,
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetMetaPerformancesByPlatformPosition(params: {
  startDate: string;
  endDate: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      "/facebook/api/ads/performance_by_platform_position",
      {
        ...params,
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}


