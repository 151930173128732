import HttpClient from "./apiClient";

export async function apiSendDataRequest(params: { data: any; hmac: string }) {
  try {
    const response = await HttpClient.post(
      "/webhooks/customers/data_request",
      {
        ...params
      },
      { headers: { "X-Shopify-Hmac-Sha256": params.hmac } }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiSendCustomerRedact(params: {
  data: any;
  hmac: string;
}) {
  try {
    const response = await HttpClient.post(
      "/webhooks/customers/redact",
      {
        ...params
      },
      { headers: { "X-Shopify-Hmac-Sha256": params.hmac } }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiSendShopRedact(params: { data: any; hmac: string }) {
  try {
    const response = await HttpClient.post(
      "/webhooks/shop/redact",
      {
        ...params
      },
      { headers: { "X-Shopify-Hmac-Sha256": params.hmac } }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyAllOrders(params: {
  shopName: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/allOrders", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifySales(params: {
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/sales", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyCustomersAll(params: {
  shopName: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/customers/all", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyCustomersNew(params: {
  shopName: string;
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/customers/new", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyCustomersRepeat(params: {
  shopName: string;
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/customers/repeat", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyRepurchaseFrequency(params: {
  shopName: string;
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/orders/repurchaseFreq", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyBestSellingProducts(params: {
  shopName: string;
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/best-selling-products", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyTopRevenueProducts(params: {
  shopName: string;
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/top-revenue-products", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetAverageTimeBetweenFirstAndSecondPurchase(params: {
  shopName: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/avg-time-between-purchases", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetSegmentCustomers(params: {
  shopName: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/segment-customers", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetTopSellingCollections(params: {
  shopName: string;
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/top-selling-collections", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetFrequentlyBoughtTogether(params: {
  shopName: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/frequently-bought-together", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetShopifyLowStockProducts(params: {
  shopName: string;
  startDate: string;
  endDate: string;
})
{
  try {
    const response = await HttpClient.getWithToken("/shopify/api/low-stock-products", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}