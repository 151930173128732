import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import { IoCloseOutline, IoPlay } from "react-icons/io5";
import TextArea from "@/components/UI/TextArea";
import ResponseResultPrompt from "../Prompt/responseResultPrompt";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/interfaces";
import {
  apiGetGoogleLowConvertingSearchTermsByMetrics,
  apiGetGoogleTopPerformingDevicesAndLocationsByMetrics,
  apiGetGoogleTopPerformingProductsCollectionsByMetrics,
  apiGetGoogleTopPerformingKeywordsByMetrics,
  apiGetGoogleTopPerformingProductsByMetrics,
  apiGetGoogleTopThreePerformingAdsByMetrics,
  apiGetGoogleTopWorstPerformingLocationByMetrics,
} from "@/shared/api/google";
import {
  apiGetMetaPerformancesByAgeGender,
  apiGetMetaPerformancesByDevices,
  apiGetMetaPerformancesByPlatformPosition,
  apiGetMetaPerformancesByRange,
  apiGetMetaPerformancesDataByDate,
} from "@/shared/api/facebook";
import { toISODateString } from "@/shared/utils/utils";
import { LoadingCircular } from "@/components/UI/LoadingCircular";
import {
  apiGetAverageTimeBetweenFirstAndSecondPurchase,
  apiGetSegmentCustomers,
  apiGetShopifyBestSellingProducts,
  apiGetShopifyTopRevenueProducts,
  apiGetTopSellingCollections,
  apiGetFrequentlyBoughtTogether,
  apiGetShopifyLowStockProducts,
} from "@/shared/api/shopify";
import MetricParamSelect from "./metricParamSelect";

interface Props {
  open: boolean;
  handleOpen: () => void;
  url: string;
}

function runMetric({ open, handleOpen, url }: Props) {
  const { shopify } = useSelector((state: RootState) => state.app);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [jsonInput, setJsonInput] = useState<string>("");
  const [isStartDate, setIsStartDate] = useState<boolean>(false);
  const [isEndDate, setIsEndDate] = useState<boolean>(false);
  const [isShopify, setIsShopify] = useState<boolean>(false);
  const [metricParam, setMetricParam] = useState<string>("ROAS");
  const [isMetricParam, setIsMetricParam] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Response State
  const [openResponsePage, setOpenResponsePage] = React.useState(false);
  const handleOpenResponsePage = () => {
    setOpenResponsePage(!openResponsePage);
  };

  useEffect(() => {
    setIsShopify(false);
    setIsStartDate(false);
    setIsEndDate(false);
    setIsMetricParam(false);
    if (url == "/google/api/top-perform-products-metric") {
      setIsStartDate(true);
      setIsEndDate(true);
      setIsMetricParam(true);
    }
    if (url == "/google/api/top-perform-products-collection-metric") {
      setIsStartDate(true);
      setIsEndDate(true);
      setIsMetricParam(true);
    } else if (url == "/google/api/top-perform-keywords-metric") {
      setIsStartDate(true);
      setIsEndDate(true);
      setIsMetricParam(true);
    } else if (url == "/google/api/low-convert-search-terms-metric") {
      setIsStartDate(true);
      setIsEndDate(true);
      setIsMetricParam(true);
    } else if (url == "/google/api/top-perform-devices-locations-metric") {
      setIsStartDate(true);
      setIsEndDate(true);
      setIsMetricParam(true);
    } else if (url == "/google/api/top-worst-perform-location-metric") {
      setIsStartDate(true);
      setIsEndDate(true);
      setIsMetricParam(true);
    } else if (url == "/google/api/top-three-perform-ads-metric") {
      setIsStartDate(true);
      setIsEndDate(true);
      setIsMetricParam(true);
    } else if (url == "/shopify/api/best-selling-products") {
      setIsShopify(true);
      setIsStartDate(true);
      setIsEndDate(true);
    } else if (url == "/shopify/api/top-revenue-products") {
      setIsShopify(true);
      setIsStartDate(true);
      setIsEndDate(true);
    } else if (url == "/shopify/api/avg-time-between-purchases") {
      setIsShopify(true);
    } else if (url == "/shopify/api/segment-customers") {
      setIsShopify(true);
    } else if (url == "/shopify/api/top-selling-collections") {
      setIsShopify(true);
      setIsStartDate(true);
      setIsEndDate(true);
    } else if (url == "/shopify/api/frequently-bought-together") {
      setIsShopify(true);
    } else if (url == "/shopify/api/low-stock-products") {
      setIsShopify(true);
      setIsStartDate(true);
      setIsEndDate(true);
    } else if (url == "/facebook/api/ads/daily_performance") {
      setIsStartDate(true);
      setIsEndDate(true);
    } else if (url == "/facebook/api/ads/performance_by_devices") {
      setIsStartDate(true);
      setIsEndDate(true);
    } else if (url == "/facebook/api/ads/performance_by_age_gender") {
      setIsStartDate(true);
      setIsEndDate(true);
    } else if (url == "/facebook/api/ads/performance_by_range") {
      setIsStartDate(true);
      setIsEndDate(true);
    } else if (url == "/facebook/api/ads/performance_by_platform_position") {
      setIsStartDate(true);
      setIsEndDate(true);
    }
  }, [url]);

  const getData = async () => {
    const start_date = toISODateString(startDate);
    const end_date = toISODateString(endDate);
    const params_google = {
      startDate: start_date,
      endDate: end_date,
      metric: metricParam,
    };
    const params_facebook = {
      startDate: start_date,
      endDate: end_date,
      metric: metricParam,
    };
    const params_shopify = {
      shopName: shopify?.shopName!,
      startDate: start_date,
      endDate: end_date,
    };

    let res = null;
    setIsLoading(true);

    if (url == "/google/api/top-perform-products-metric") {
      res = await apiGetGoogleTopPerformingProductsByMetrics(params_google);
    } else if (url == "/google/api/top-perform-products-collection-metric") {
      res = await apiGetGoogleTopPerformingProductsCollectionsByMetrics(
        params_google
      );
    } else if (url == "/google/api/top-perform-keywords-metric") {
      res = await apiGetGoogleTopPerformingKeywordsByMetrics(params_google);
    } else if (url == "/google/api/low-convert-search-terms-metric") {
      res = await apiGetGoogleLowConvertingSearchTermsByMetrics(params_google);
    } else if (url == "/google/api/top-perform-devices-locations-metric") {
      res = await apiGetGoogleTopPerformingDevicesAndLocationsByMetrics(
        params_google
      );
    } else if (url == "/google/api/top-worst-perform-location-metric") {
      res = await apiGetGoogleTopWorstPerformingLocationByMetrics(
        params_google
      );
    } else if (url == "/google/api/top-three-perform-ads-metric") {
      res = await apiGetGoogleTopThreePerformingAdsByMetrics(params_google);
    } else if (url == "/shopify/api/best-selling-products") {
      res = await apiGetShopifyBestSellingProducts(params_shopify);
    } else if (url == "/shopify/api/top-revenue-products") {
      res = await apiGetShopifyTopRevenueProducts(params_shopify);
    } else if (url == "/shopify/api/avg-time-between-purchases") {
      res = await apiGetAverageTimeBetweenFirstAndSecondPurchase({
        shopName: shopify?.shopName!,
      });
    } else if (url == "/shopify/api/segment-customers") {
      res = await apiGetSegmentCustomers({ shopName: shopify?.shopName! });
    } else if (url == "/shopify/api/top-selling-collections") {
      res = await apiGetTopSellingCollections(params_shopify);
    } else if (url == "/shopify/api/frequently-bought-together") {
      res = await apiGetFrequentlyBoughtTogether({
        shopName: shopify?.shopName!,
      });
    } else if (url == "/shopify/api/low-stock-products") {
      res = await apiGetShopifyLowStockProducts(params_shopify);
    } else if (url == "/facebook/api/ads/daily_performance") {
      res = await apiGetMetaPerformancesDataByDate(params_facebook);
    } else if (url == "/facebook/api/ads/performance_by_devices") {
      res = await apiGetMetaPerformancesByDevices(params_facebook);
    } else if (url == "/facebook/api/ads/performance_by_age_gender") {
      res = await apiGetMetaPerformancesByAgeGender(params_facebook);
    } else if (url == "/facebook/api/ads/performance_by_range") {
      res = await apiGetMetaPerformancesByRange(params_facebook);
    } else if (url == "/facebook/api/ads/performance_by_platform_position") {
      res = await apiGetMetaPerformancesByPlatformPosition(params_facebook);
    }
    setIsLoading(false);
    // console.log(res);
    if (res?.success) {
      const prettyJson = JSON.stringify(res.data, null, 2);
      setJsonInput(prettyJson);
    }
  };

  return (
    <>
      <Dialog
        placeholder
        size="xl"
        open={open}
        handler={() => {}}
        className="p-4"
      >
        <DialogHeader placeholder className="relative m-0 block">
          <Typography placeholder variant="h4" color="blue-gray">
            Database
          </Typography>
          <Typography placeholder variant="small" color="blue-gray">
            {url}
          </Typography>
          <IconButton
            placeholder
            size="sm"
            variant="text"
            className="!absolute right-3.5 top-3.5"
            onClick={handleOpen}
          >
            <IoCloseOutline className="h-4 w-4 stroke-2" />
          </IconButton>
        </DialogHeader>
        <DialogBody placeholder className="space-y-4 pb-6">
          <div className="flex gap-4">
            <div className="w-1/2 flex flex-col gap-12">
              <div className="flex flex-col gap-2">
                <Typography
                  placeholder
                  variant="small"
                  color="blue-gray"
                  className="mb-1 text-left font-medium"
                >
                  {"Parameters"}
                </Typography>
                <div className="flex">
                  <Checkbox crossOrigin checked={isShopify} />
                  <Input
                    crossOrigin
                    label="Store Name"
                    type="text"
                    value={shopify?.shopName!}
                  />
                </div>
                <div className="flex">
                  <Checkbox crossOrigin checked={isStartDate} />
                  <Input
                    crossOrigin
                    label="Start-Date"
                    type="date"
                    value={startDate.toISOString().split("T")[0]}
                    onChange={(e) => {
                      setStartDate(new Date(e.target.value));
                    }}
                  />
                </div>
                <div className="flex">
                  <Checkbox crossOrigin checked={isEndDate} />
                  <Input
                    crossOrigin
                    label="End-Date"
                    type="date"
                    value={endDate.toISOString().split("T")[0]}
                    onChange={(e) => {
                      setEndDate(new Date(e.target.value));
                    }}
                  />
                </div>
                <div className="flex">
                  <Checkbox crossOrigin checked={isMetricParam} />
                  <MetricParamSelect
                    data={metricParam}
                    setData={setMetricParam}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 relative">
              {isLoading && (
                <div className="absolute left-20">
                  <LoadingCircular color="blue" size="small" />
                </div>
              )}
              <TextArea
                label="Response"
                value={jsonInput}
                row={25}
                cols={50}
                bgColor="#49cc908A"
              />
            </div>
          </div>
        </DialogBody>
        <DialogFooter placeholder>
          <Button
            placeholder
            className="flex items-center gap-3 mr-2"
            color="blue"
            onClick={() => {
              getData();
            }}
          >
            <IoPlay size="16" /> Run
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default runMetric;
