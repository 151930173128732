import styled from "styled-components";
import { IoSearchOutline } from "react-icons/io5";
interface Props {
  label?: string;
  type: string;
  value: string;
  color?: string;
  shadow?: boolean;
  onChangeHandler?: (e: any) => void;
}

export default function SearchInput({label, type, value, color, shadow=false, onChangeHandler}: Props) {
  return (
    <div className="relative w-full">
      <StyledInput
        type={type}
        color={color}
        shadow={shadow}
        placeholder={label}
        value={value}
        required
        onChange={onChangeHandler}
        className="w-full text-xs bg-transparent border rounded-md border-blue-gray-200/90 py-2.5 pl-8 pr-4 focus:!border-gray-900 focus:outline-none"
      />
      <div className="absolute top-2.5 left-2"><IoSearchOutline size={18} color="#cccac6"/></div>
    </div>
  );
}

const StyledInput = styled.input<{color?: string, shadow?:boolean}>`
  &:focus {
    outline: none;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 500px ${({ color, theme }) => color? color == 'black' ? 'white' : 'black' : theme.backgroundColor}
      inset !important;
    box-shadow: 0 0 0 500px ${({ color, theme }) => color? color == 'black' ? '#eeeeee' : 'black' : theme.backgroundColor} inset !important;
    -webkit-text-fill-color: ${({ color, theme }) => color ? color : theme.textColor};
  }
  color: ${({ color, theme }) => color ? color : theme.textColor};
  border-width: 1px;
  box-shadow: ${({ shadow }) => shadow ? '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)' : 'none'};
`;