import HttpClient from "./apiClient";

export async function apiGetPrompts(params: {
  channel?: string;
  query?: string;
  isOnlyEnabledPrompts?: boolean;
  limit: number;
  offset: number;
})
{
  try {
    const response = await HttpClient.get("/prompts", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetRecentPrompts(params: {
  promptId?: string | null;
}) {
  try {
    const response = await HttpClient.get(
      "/prompts/recent", {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiSendPrompt(params: {
  data: any;
}) {
  try {
    const response = await HttpClient.postWithToken(
      "/prompts/recent",
      {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeleteRecentPrompt(params: {
  promptId: string;
}) {
  try {
    const response = await HttpClient.postWithToken(
      "/prompts/recent/delete",
      {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetHelpList(params: {
  query?: string;
  limit: number;
  offset: number;
})
{
  try {
    const response = await HttpClient.get("/help", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}


export async function apiGetHelp(params: {
  id: string;
})
{
  try {
    const response = await HttpClient.get("/help/detail", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletePrompt(params: {
  promptId: string;
}) {
  try {
    const response = await HttpClient.postWithToken(
      "/prompts/delete",
      {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiOnEnablePrompt(params: {
  promptId: string;
  enabled: boolean;
}) {
  try {
    const response = await HttpClient.postWithToken(
      "/prompts/enable",
      {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiUpdatePrompt(params: {
  promptId: string;
  label: string;
  prompt: string;
  prompt_body: any;
  channel: string;
  isSendOpenAI: boolean;
}) {
  try {
    const response = await HttpClient.postWithToken(
      "/prompts/update",
      {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiAddNewPrompt(params: {
  label: string;
  prompt: string;
  prompt_body: any;
  channel: string;
  isAutomation?: boolean;
}) {
  try {
    const response = await HttpClient.postWithToken(
      "/prompts/add",
      {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

// prompts/run
export async function apiRunPrompt(params: {
  promptId: string;
  startDate: string;
  endDate: string;
  metric: string;
  prompt_body: any;
  isSendOpenAI: boolean;
  isAutomation: boolean;
}) {
  try {
    const response = await HttpClient.postWithToken(
      "/prompts/run",
      {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}