import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Select, Option } from "@material-tailwind/react";
import { channels } from "@/shared/utils/constants";
import { Channel } from '@/types/enum';
import { channelTypeToIndex, indexToChannelType } from '@/shared/utils/utils';

interface Props {
  channelType: Channel;
  isBorder?: boolean;
  setChannelType?: (channelType: Channel) => void;
}

function ChannelSelect({channelType, isBorder, setChannelType}: Props) {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(channelTypeToIndex[channelType].toString());

  useEffect(() => {
    if(channelType) {
      setSelectedOption(channelTypeToIndex[channelType].toString());
    }
  }, [channelType])

  const handleChange = (value: any) => {
    setSelectedOption(value);
    onChangeChannel(parseInt(value));
  };
  const onChangeChannel = (index: number) => {
    const newChannelType = indexToChannelType[index];
    if(!setChannelType)
    {
      if(newChannelType == Channel.AllChannels) {
        navigate('/');
      } else if(newChannelType == Channel.MetaAds) {
        navigate('/ads/meta');
      } else if(newChannelType == Channel.GoogleAds) {
        navigate('/ads/google');
      } else if(newChannelType == Channel.Shopify) {
        navigate('/ads/shopify');
      } else if(newChannelType == Channel.Email) {
        navigate('/ads/email');
      }
    }
    else
    {
      setChannelType(newChannelType);
    }
  }

  return (
    <div className="z-50">
      <Select placeholder
        value={selectedOption}
        onChange={handleChange}
        className={`border-0 font-normal text-xs`}
        labelProps={{ className: "hidden" }}
        containerProps={{ className: `!min-w-[160px] text-left transition-all rounded-md bg-white focus:opacity-[0.85] active:opacity-[0.85] active:shadow-none ${isBorder ? '!border !border-blue-gray-200/90 !border-t-blue-gray-200/90' : '!min-w-[130px] !h-8 shadow-md shadow-blue-gray-500/10 focus:shadow-none hover:shadow-lg hover:shadow-blue-gray-500/20'}` }}
        menuProps={{ className: `font-normal z-50 text-xs` }}
      >
        {channels.map((channel, index) => (
          <Option key={index} value={index.toString()}>{channel.label}</Option>
        ))}
      </Select>
    </div>
  )
}

export default ChannelSelect