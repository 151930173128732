import TextArea from "@/components/UI/TextArea";
import { ChartDate, IChartData } from "@/types/props";
import { DonutChart } from "@shopify/polaris-viz";
import { useEffect, useState } from "react";

interface Props {
  data: any;
}

const mock = [
  {
    "name": "Shopify Payments",
    "data": [
      {
        "key": "april - march",
        "value": 50000
      },
    ]
  },
  {
    "name": "Paypal",
    "data": [
      {
        "key": "april - march",
        "value": 25000
      }
    ]
  },
  {
    "name": "Other",
    "data": [
      {
        "key": "april - march",
        "value": 10000
      }
    ]
  },
  {
    "name": "Amazon Pay",
    "data": [
      {
        "key": "april - march",
        "value": 4000
      }
    ]
  }
]

interface ChartData {
  data: ChartDate[];
  name: string;
}

function PieChart({ data }: Props) {
  const [dt, setDt] = useState<ChartData[]>([]);
  const [isShow, setIsShow] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      if (data?.url.includes('google')) {
        setIsShow(true);
        let chartDataArray: ChartData[] = [];
        data?.data.forEach((item: any) => {
          let chartData: ChartData = { data: [{ key: item.productId, value: item[data?.metric] }], name: item.productTitle };
          chartDataArray.push(chartData);
        });
        setDt(chartDataArray);
      }
      else {
        setIsShow(false);
      }
    }
  }, [data])

  return (
    <>
    {isShow && (
      <div className="flex flex-col">
        <p className="text-base font-medium ml-2 mb-2">{data?.name}</p>
        <DonutChart
          comparisonMetric={{
            "metric": data?.metric,
            "trend": "neutral",
            "accessibilityLabel": ""
          }}
          data={dt}
        />
        {/* <TextArea label="Response" value={dt} row={10} cols={50} bgColor="bg-green-200" /> */}
      </div>
    )}
    </>
  )
}

export default PieChart