import HttpClient from "./apiClient";

export async function apiGetGoogleAnalyticsVisitors(params: {
  startDate: string;
  endDate: string;
}) {
  try {
    const response = await HttpClient.getWithToken("/google/api/visitors", {
      ...params,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetGoogleKpisData(params: {
  startDate: string;
  endDate: string;
}) {
  try {
    const response = await HttpClient.getWithToken("/google/api/ads/kpi", {
      ...params,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetGoogleTopPerformingProductsByMetrics(params: {
  startDate: string;
  endDate: string;
  metric?: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      "/google/api/top-perform-products-metric",
      {
        ...params,
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetGoogleTopPerformingProductsCollectionsByMetrics(params: {
  startDate: string;
  endDate: string;
  metric?: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      "/google/api/top-perform-products-collection-metric",
      {
        ...params,
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetGoogleTopPerformingKeywordsByMetrics(params: {
  startDate: string;
  endDate: string;
  metric?: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      "/google/api/top-perform-keywords-metric",
      {
        ...params,
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
// low-convert-search-terms-metric
export async function apiGetGoogleLowConvertingSearchTermsByMetrics(params: {
  startDate: string;
  endDate: string;
  metric?: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      "/google/api/low-convert-search-terms-metric",
      {
        ...params,
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
// top-perform-devices-locations-metric
export async function apiGetGoogleTopPerformingDevicesAndLocationsByMetrics(params: {
  startDate: string;
  endDate: string;
  metric?: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      "/google/api/top-perform-devices-locations-metric",
      {
        ...params,
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
// top-worst-perform-location-metric
export async function apiGetGoogleTopWorstPerformingLocationByMetrics(params: {
  startDate: string;
  endDate: string;
  metric?: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      "/google/api/top-worst-perform-location-metric",
      {
        ...params,
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
// top-three-perform-ads-metric
export async function apiGetGoogleTopThreePerformingAdsByMetrics(params: {
  startDate: string;
  endDate: string;
  metric?: string;
}) {
  try {
    const response = await HttpClient.getWithToken(
      "/google/api/top-three-perform-ads-metric",
      {
        ...params,
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
