import React, { useEffect, useState } from "react";
import {
  Input,
  Option,
  Select,
  Button,
  Dialog,
  Tooltip,
  IconButton,
  Typography,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Chip,
  Checkbox
} from "@material-tailwind/react";
import { IoCloseOutline } from "react-icons/io5";
import { LuPlus } from "react-icons/lu";
import { HiPencil } from "react-icons/hi2";
import { MdDelete } from "react-icons/md";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { IoPlay } from "react-icons/io5";
import CustomInput from "@/components/UI/CustomInput";
import EditQuestion from "./Question/editQuestion";
import AddQuestion from "./Question/addQuestion";
import ResponseResultPrompt from "./responseResultPrompt";
import InputBox from "@/components/UI/InputBox";
import ChannelSelect from "@/components/UI/ChannelSelect";
import { Channel } from "@/types/enum";
import TextArea from "@/components/UI/TextArea";
import { apiRunPrompt, apiUpdatePrompt } from "@/shared/api/common";
import { showToast } from "@/components/UI/PrimaryToast";
import { LoadingCircular } from "@/components/UI/LoadingCircular";

const TABLE_HEAD = ["GPT Prompt", "Action"];
interface Props {
  open: boolean;
  handleOpen: () => void;
  promptData: any;
}

function EditPrompt({ open, handleOpen, promptData }: Props) {
  const [pData, setPData] = useState<any>(promptData);
  const [channelType, setChannelType] = useState<Channel>(Channel.AllChannels);
  const [label, setLabel] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [isSendOpenAI, setIsSendOpenAI] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resData, setResData] = useState<any>(null);

  useEffect(() => {
    setPData(promptData);
    let c = promptData?.channel;
    if (Object.values(Channel).includes(c as Channel)) {
      setChannelType(c as Channel);
    }
    setLabel(promptData?.label);
    setText(promptData?.prompt);
    setIsSendOpenAI(promptData?.isSendOpenAI);
  }, [promptData]);

  const onSave = async () => {
    if(!label || !text || !channelType) {
      showToast("error", `Please fill all the fields!`);
      return;
    }
    const res = await apiUpdatePrompt({
      promptId: pData?._id,
      label: label,
      prompt: text,
      prompt_body: pData?.prompt_body,
      channel: channelType,
      isSendOpenAI: isSendOpenAI
    });
    if (res?.success) {
      showToast("success", "Updated Successfully!");
      handleOpen();
    }
  };

  // Edit State
  const [openEditPage, setOpenEditPage] = React.useState(false);
  const onOpenEditPage = (index: number, question: any) => {
    setSelectedQuestion(question);
    setSelectedQuestionIndex(index);
    setTimeout(() => {
      handleOpenEditPage();
    }, 100);
  };
  const handleOpenEditPage = () => {
    setOpenEditPage(!openEditPage);
  };
  // Add State
  const [openAddPage, setOpenAddPage] = React.useState(false);
  const handleOpenAddPage = () => {
    setOpenAddPage(!openAddPage);
  };

  // Response State
  const [openResponsePage, setOpenResponsePage] = React.useState(false);
  const handleOpenResponsePage = () => {
    setOpenResponsePage(!openResponsePage);
  };

  const onEditQuestion = (question: any) => {
    setPData((prev: any) => {
      prev.prompt_body[selectedQuestionIndex] = question;
      return prev;
    });
  };

  const onDeleteQuestion = (selectedIndex: number) => {
    if(pData?.prompt_body.length <= 1) return;
    setPData((prev: any) => {
      prev.prompt_body.splice(selectedIndex, 1);
      return prev;
    });
    setPData({ ...pData });
  }

  const onAddQuestion = (question: any) => {
    setPData((prev: any) => {
      prev.prompt_body.push(question);
      return prev;
    });
    setPData({ ...pData });
  }

  return (
    <>
      <Dialog placeholder size="xxl" open={open} handler={() => {}} className="p-4">
        <DialogHeader placeholder className="relative m-0 block">
          <Typography placeholder variant="h4" color="blue-gray">
            Edit Prompt
          </Typography>
          <Typography placeholder className="mt-1 font-normal text-gray-600">
            #ID: {pData?._id}
          </Typography>
          <IconButton placeholder size="sm" variant="text" className="!absolute right-3.5 top-3.5" onClick={handleOpen}>
            <IoCloseOutline className="h-4 w-4 stroke-2" />
          </IconButton>
        </DialogHeader>
        <DialogBody placeholder className="space-y-4 pb-6">
          <div className="-m-2.5">
            <Checkbox
              crossOrigin
              checked={isSendOpenAI}
              onChange={() => setIsSendOpenAI(!isSendOpenAI)}
              label={
                <Typography placeholder color="blue-gray" className="text-sm font-normal">
                  Send to open AI api or not?
                </Typography>
              }
            />
          </div>
          <div className="flex gap-4">
            <InputBox
              label="User Prompt"
              placeholder="user prompt"
              value={label}
              onChangeHandler={(e) => {
                setLabel(e.target.value);
              }}
            />
            <div style={{ zIndex: 999 }}>
              <Typography placeholder variant="small" color="blue-gray" className="mb-2 text-left font-medium">
                Channel
              </Typography>
              <ChannelSelect channelType={channelType} setChannelType={setChannelType} isBorder />
            </div>
          </div>
          <TextArea
            label="User Question"
            value={text}
            setValue={setText}
            placeholder="user question"
            isTag={true}
            onChangeHandler={(e) => {
              setText(e.target.value);
            }}
          />
          <div className="flex justify-end">
            <Button
              placeholder
              className="flex items-center gap-2 rounded-md"
              size="sm"
              variant="outlined"
              onClick={handleOpenAddPage}
            >
              <LuPlus size={16} />
              <p className="text-xs font-normal capitalize">Add GPT Prompt</p>
            </Button>
          </div>
          <div className="overflow-auto h-72">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head, index) => (
                    <th
                      key={head}
                      className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                    >
                      <Typography
                        placeholder
                        variant="small"
                        color="blue-gray"
                        className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                      >
                        {head}{" "}
                        {/* {index !== TABLE_HEAD.length - 1 && (
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    )} */}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {pData?.prompt_body.map((question: any, index: number) => {
                  const isLast = index === pData?.prompt_body.length - 1;
                  const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                  return (
                    <tr key={index} className="even:bg-blue-gray-50/50">
                      <td className={classes}>
                        <div className="max-w-3xl">
                          <Typography placeholder variant="small" color="blue-gray" className="truncate font-normal">
                            {question?.question}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <Tooltip content="Edit">
                          <IconButton
                            placeholder
                            variant="text"
                            onClick={() => {
                              onOpenEditPage(index, question);
                            }}
                          >
                            <HiPencil size={16} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip content="Delete">
                          <IconButton placeholder variant="text" onClick={()=>{onDeleteQuestion(index)}}>
                            <MdDelete size={19} />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </DialogBody>
        <DialogFooter placeholder>
          {/* <Button placeholder className="flex items-center gap-3" color="blue" onClick={onRunPrompt}>
            <IoPlay size="16" /> Run
            {isLoading && <LoadingCircular color="red" size="small" />}
          </Button> */}
          <Button placeholder className="ml-3" onClick={onSave}>
            Save
          </Button>
        </DialogFooter>
      </Dialog>
      <EditQuestion
        open={openEditPage}
        handleOpen={handleOpenEditPage}
        data={selectedQuestion}
        onEditQuestion={onEditQuestion}
      />
      <AddQuestion open={openAddPage} handleOpen={handleOpenAddPage} onAddQuestion={onAddQuestion}/>
      {/* {resData && <ResponseResultPrompt open={openResponsePage} handleOpen={handleOpenResponsePage} data={resData}/>} */}
    </>
  );
}

export default EditPrompt;
