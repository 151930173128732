import { Button, Textarea, Typography } from "@material-tailwind/react";

interface Props {
  label?: string;
  placeholder?: string;
  value?: string;
  setValue?: (value: string) => void;
  maxLength?: number;
  row?: number;
  cols?: number;
  bgColor?: string;
  isTag?: boolean;
  onChangeHandler?: (e: any) => void;
}

export default function TextArea({label, placeholder, value, setValue, maxLength, row, cols, bgColor, isTag, onChangeHandler}: Props) {
  const onAddTag = (tag: string) => {
    setValue && setValue(value + tag);
  }
  return (
    <div className="w-full">
      <Typography placeholder
        variant="small"
        color="blue-gray"
        className="mb-2 text-left font-medium"
      >
        {label}
      </Typography>
      <Textarea placeholder={placeholder} value={value} rows={row} cols={cols} maxLength={maxLength && maxLength}
        className={`!w-full !border !border-blue-gray-200/90 text-sm text-gray-900 placeholder:opacity-60 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10`}
        style={{ backgroundColor: bgColor ?? "white" }}
        labelProps={{
          className: "hidden",
        }}
        containerProps={{ className: "min-w-[100px]" }}
        onChange={onChangeHandler}
      />
      {maxLength && <div className="flex justify-end mb-4">
        <Typography placeholder
          variant="small"
          color="blue-gray"
          className="text-right font-medium"
        >
          {value?.length}/{maxLength}
        </Typography>
      </div>}
      {isTag && <div className="flex gap-2 mt-2 mb-4">
        <Button
          placeholder
          size="sm"
          onClick={()=>{onAddTag("<CHANNELS>")}}
        >
          <p className="text-xs font-normal capitalize">Channels</p>
        </Button>
        <Button
          placeholder
          size="sm"
          onClick={()=>{onAddTag("<PRODUCT>")}}
        >
          <p className="text-xs font-normal capitalize">Product</p>
        </Button>
        <Button
          placeholder
          size="sm"
          onClick={()=>{onAddTag("<KPIS>")}}
        >
          <p className="text-xs font-normal capitalize">KPIs</p>
        </Button>
        <Button
          placeholder
          size="sm"
          onClick={()=>{onAddTag("<DATE_RANGE>")}}
        >
          <p className="text-xs font-normal capitalize">Date Range</p>
        </Button>
      </div>}
    </div>
  );
}