import { metricCharts } from '@/shared/utils/constants'
import { Select, Option, Typography } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'

interface Props {
  data?: string | null;
  setData?: (url: string) => void;
}

function MetricChartSelect({data, setData}: Props) {
  const [selectedOption, setSelectedOption] = useState("0");

  useEffect(() => {
    if(data) {
      setSelectedOption(metricCharts.findIndex(metric => metric.label == data).toString());
    } else {
      setSelectedOption("0");
      onChange(0);
    }
  }, [data, setData]);

  const handleChange = (value: any) => {
    setSelectedOption(value);
    onChange(parseInt(value));
  };

  const onChange = (index: number) => {
    if(setData)
    {
      setData(metricCharts[index].label);
    }
  }
  return (
    <div className="relative w-full mb-3">
      <Typography placeholder
        variant="small"
        color="blue-gray"
        className="mb-2 text-left font-medium"
      >
        {"Chart Type"}
      </Typography>
      <Select placeholder
        value={selectedOption}
        onChange={handleChange}
        className="border-0 text-sm font-normal"
        labelProps={{ className: "hidden" }}
        containerProps={{ className: "!w-full !border !border-blue-gray-200/90 !border-t-blue-gray-200/90 rounded-md bg-white text-gray-800 ring-4 ring-transparent placeholder:text-gray-600 focus:!border-primary focus:!border-t-blue-gray-900 group-hover:!border-primary"}}
        menuProps={{ className: "font-normal text-sm z-50" }}
      >
        {metricCharts.map((metric, index) => (
          <Option key={index} value={index.toString()}>{metric.label}</Option>
        ))}
      </Select>
    </div>
  )
}

export default MetricChartSelect