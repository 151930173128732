import HttpClient from "./apiClient";

export async function apiGetMetrics(params: {
  query?: string;
  limit: number;
  offset: number;
})
{
  try {
    const response = await HttpClient.get("/metrics", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetMetric(params: {
  id: string;
})
{
  try {
    const response = await HttpClient.get("/metrics/one", {
      ...params
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiAddNewMetric(params: {
  name: string;
  description: string;
  url: string;
  request: any;
  chart: string;
  params: any;
}) {
  try {
    const response = await HttpClient.postWithToken(
      "/metrics/add",
      {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeleteMetric(params: {
  metricId: string;
}) {
  try {
    const response = await HttpClient.postWithToken(
      "/metrics/delete",
      {
        ...params
      }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}