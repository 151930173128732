import React, { useCallback, useEffect, useState } from 'react'
import { RootState } from "@/redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { Channel } from "@/types/enum";
import { useInfinitePrompts } from '@/hooks/useInfiniteQueries';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Spinner } from "@material-tailwind/react";
import SearchInput from '@/components/UI/SearchInput';
import appAction from "@/redux/app/action";
import PromptCard from "@/components/UI/PromptCard";
import _ from "lodash";
interface Props {
  channelType: Channel;
  isLibraryPage: boolean;
}

function PromptLibraryPage({ channelType: _channelType, isLibraryPage }: Props) {
  const dispatch = useDispatch();
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(0);
  const [channelType, setChannelType] = useState<Channel>(_channelType);
  const [promptData, setPromptData] = useState<string[]>([]);
  const [query, setQuery] = useState<string>("");

  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfinitePrompts(channelType, query, true, 40);

  const debouncedRefetch = useCallback(
    _.debounce(() => {
      refetch();
    }, 300),
    [refetch]
  );

  useEffect(() => {
    debouncedRefetch();
    // clean-up function to cancel debounce on unmount
    return () => {
      debouncedRefetch.cancel();
    };
  }, [query, debouncedRefetch]);

  useEffect(() => {
    if (data) {
      const arrData = data
        ? data.pages.flatMap((page: any) => page?.data ?? [])
        : [];
      setPromptData(arrData);
    }
  }, [data]);

  useEffect(() => {
    if(isLibraryPage && promptData.length > 0) {
      onSelectPromptCard(selectedCardIndex);
    }
  }, [isLibraryPage, promptData]);

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      hasNextPage && !isFetchingNextPage && fetchNextPage();
    }
  };

  const onSelectPromptCard = (index: number) => {
    setSelectedCardIndex(index);
    dispatch(appAction.onUpdatePromptLibraryData(promptData[index]));
  }

  return (
    <>
      <div className="flex justify-end">
        <div className="w-64 mb-4 pt-3">
          <SearchInput
            label={'Search'}
            type="text"
            value={query}
            color="black"
            onChangeHandler={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>
      </div>
      <Scrollbars className='flex-1'
        style={{ paddingBottom: 100 }}
        onScroll={handleScroll}
      >
        <div className="text-left grid sm:grid-cols-4 grid-cols-2 gap-3 mb-2">
          {promptData.length > 0 && promptData.map((item: any, index: number) => (
            <PromptCard
              key={index}
              data={item}
              selected={selectedCardIndex == index}
              onChangeHandler={() => {
                onSelectPromptCard(index);
              }}
            />
          ))}
        </div>
        {isFetchingNextPage && <Spinner className='mx-auto' />}
      </Scrollbars>
    </>
  )
}

export default PromptLibraryPage