import { Card, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";

interface Props {
  data: any;
}

export function DefaultTable({ data }: Props) {
  const [isGoogle, setIsGoogle] = useState<boolean>(false);
  const [dt, setDt] = useState<any[]>([]);
  const [head, setHead] = useState<any[]>([]);
  // shopify segment customers
  const [segmanetCustomers_top, setSegmentCustomers_top] = useState<any[]>([]);
  const [segmanetCustomers_bottom, setSegmentCustomers_bottom] = useState<any[]>([]);
  const [isShow, setIsShow] = useState<boolean>(false);

  useEffect(() => {
    if (data?.url.includes('google')) {
      setIsShow(true);
      setIsGoogle(true);
      if (data && data?.data.length > 0) {
        const item = data?.data[0];
        setHead(Object.keys(item));
        setDt(data?.data);
      }
    }
    else if (data?.url == "/shopify/api/segment-customers") {
      setIsShow(true);
      setHead(Object.keys(data?.data["googleCustomers_bottom"][0]));
      setSegmentCustomers_top(data?.data["googleCustomers_top"]);
      setSegmentCustomers_bottom(data?.data["googleCustomers_bottom"]);
    }
    else if (data?.url == "/shopify/api/best-selling-products") {
      setIsShow(true);
      const item = data?.data[0];
      const nonObjectKeys = Object.keys(item).filter(
        (key) => typeof item[key as keyof typeof item] !== "object"
      );
      setHead(nonObjectKeys);
      setDt(data?.data);
    } else {
      setIsShow(false);
    }
  }, [data])

  return (
    <>
      {isShow && (
        <Card placeholder className="h-full w-full overflow-scroll">
          <p className="text-base font-medium ml-2 mb-2">{data?.name}</p>
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {head.length > 0 && head.map((head) => (
                  <th
                    key={head}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography placeholder
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            {isGoogle ? <tbody>
              {dt.length > 0 && dt.map((item: any, index: number) => {
                const isLast = index === dt.length - 1;
                const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={index}>
                    {head.map((key) => (
                      <td key={key} className={classes}>
                        <Typography placeholder
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none"
                        >
                          {item[key]}
                        </Typography>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody> :
              data?.url == "/shopify/api/segment-customers" ?
                <>
                  <tbody>
                    {segmanetCustomers_top.length > 0 && segmanetCustomers_top.map((item: any, index: number) => {
                      const isLast = index === segmanetCustomers_top.length - 1;
                      const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr key={index}>
                          {head.map((key) => (
                            <td key={key} className={classes}>
                              <Typography placeholder
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none"
                              >
                                {item[key]}
                              </Typography>
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                  <tbody>
                    {segmanetCustomers_bottom.length > 0 && segmanetCustomers_bottom.map((item: any, index: number) => {
                      const isLast = index === segmanetCustomers_bottom.length - 1;
                      const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr key={index}>
                          {head.map((key) => (
                            <td key={key} className={classes}>
                              <Typography placeholder
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none"
                              >
                                {item[key]}
                              </Typography>
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </>
                :
                <>
                  <tbody>
                    {dt.length > 0 && dt.map((item: any, index: number) => {
                      const isLast = index === dt.length - 1;
                      const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr key={index}>
                          {head.map((key) => {
                            if (typeof item[key] !== 'object') {
                              return (
                                <td key={key} className={classes}>
                                  <Typography placeholder
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none"
                                  >
                                    {item[key]}
                                  </Typography>
                                </td>
                              )
                            }
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </>
            }
          </table>
        </Card>
      )}
    </>
  );
}