import { useInfiniteMetrics } from '@/hooks/useInfiniteQueries';
import { mockMetrics } from '@/shared/utils/constants'
import { Select, Option, Typography } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'

interface Props {
  data?: string | null;
  setData?: (id: string) => void;
}

function MetricsSelect({ data, setData }: Props) {
  const [selectedOption, setSelectedOption] = useState("0");
  const [metricData, setMetricData] = useState<any>(mockMetrics);

  const {
    data: metrics,
    error,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    isFetched,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    refetch,
  } = useInfiniteMetrics('', 50);

  useEffect(() => {
    if (metrics) {
      const arrData = metrics
        ? metrics.pages.flatMap((page: any) => page?.data ?? [])
        : [];
      setMetricData(arrData);
      setSelectedOption("0");
    }
  }, [metrics]);

  useEffect(() => {
    if (data) {
      setSelectedOption(metricData.findIndex((metric: any) => metric._id == data).toString());
    } else {
      setSelectedOption("0");
      onChange(0);
    }
  }, [data, metricData]);

  const handleChange = (value: any) => {
    setSelectedOption(value);
    onChange(parseInt(value));
  };

  const onChange = (index: number) => {
    if(metricData && metricData.length > 0)
      setData && setData(metricData[index]._id);
    else {
      console.log('No Metrics Found');
    }
  }

  return (
    <div className="relative w-full">
      <Typography placeholder
        variant="small"
        color="blue-gray"
        className="mb-2 text-left font-medium"
      >
        {"Database"}
      </Typography>
      {(metricData && metricData.length > 0) ? <Select placeholder
        size="md"
        value={selectedOption}
        onChange={handleChange}
        className="border-0 text-sm font-normal"
        labelProps={{ className: "hidden" }}
        containerProps={{ className: "!w-full !border !border-blue-gray-200/90 !border-t-blue-gray-200/90 rounded-md bg-white text-gray-800 ring-4 ring-transparent placeholder:text-gray-600 focus:!border-primary focus:!border-t-blue-gray-900 group-hover:!border-primary"}}
        menuProps={{ className: "font-normal text-sm z-50 bg-blue-gray-100" }}
      >
        {metricData.map((metric: any, index: number) => (
          <Option key={index} value={index.toString()}>{metric.name}</Option>
        ))}
      </Select> :
      <Typography placeholder
        variant="small"
        color="red"
        className="mb-2 text-left font-medium"
      >
        {"No Metric"}
      </Typography>
      }
    </div>
  )
}

export default MetricsSelect