import { useInfiniteQuery } from "@tanstack/react-query";
import { apiGetPrompts, apiGetHelpList } from "@/shared/api/common";
import { apiGetMetrics } from "@/shared/api/metric";

export const useInfinitePrompts = (channel: string, query: string, isOnlyEnabledPrompts: boolean, limit?: number) => {
  return useInfiniteQuery({
    queryKey: ["prompts", channel, limit],
    queryFn: async ({ pageParam = 0 }) => {
      const data = await apiGetPrompts({ channel, query, isOnlyEnabledPrompts, limit: limit ?? 10, offset: pageParam });
      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const nextOffset = allPages.length * (limit ?? 10);
      return lastPage.hasMore ? nextOffset : undefined;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      const prevOffset = (allPages.length - 2) * (limit ?? 10);
      return prevOffset >= 0 ? prevOffset : undefined;
    },
    enabled: !!channel
  });
};

export const useInfiniteHelps = (query: string, limit?: number) => {
  return useInfiniteQuery({
    queryKey: ["helps", query, limit],
    queryFn: async ({ pageParam = 0 }) => {
      const data = await apiGetHelpList({ query, limit: limit ?? 10, offset: pageParam });
      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const nextOffset = allPages.length * (limit ?? 10);
      return lastPage.hasMore ? nextOffset : undefined;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      const prevOffset = (allPages.length - 2) * (limit ?? 10);
      return prevOffset >= 0 ? prevOffset : undefined;
    }
  });
};

export const useInfiniteMetrics = (query: string, limit?: number) => {
  return useInfiniteQuery({
    queryKey: ["metrics", limit],
    queryFn: async ({ pageParam = 0 }) => {
      const data = await apiGetMetrics({ query, limit: limit ?? 10, offset: pageParam });
      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const nextOffset = allPages.length * (limit ?? 10);
      return lastPage.hasMore ? nextOffset : undefined;
    },
    getPreviousPageParam: (firstPage, allPages) => {
      const prevOffset = (allPages.length - 2) * (limit ?? 10);
      return prevOffset >= 0 ? prevOffset : undefined;
    }
  });
};