import React, { useCallback, useEffect, useState } from 'react';
import SearchInput from '@/components/UI/SearchInput';
import { Channel } from '@/types/enum';
import ChannelSelect from '@/components/UI/ChannelSelect';
import {
  Card,
  Typography,
  Button,
  CardBody,
  CardFooter,
} from '@material-tailwind/react';
import { LuPlus } from "react-icons/lu";
import EditPrompt from './editPrompt';
import AddPrompt from './addPrompt';
import ResponseResultPrompt from "./responseResultPrompt";
import { useInfinitePrompts } from '@/hooks/useInfiniteQueries';
import _ from 'lodash';
import PromptTableRow from './promptTableRow';
import RunPrompt from './runPrompt';
import AddAutomationPrompt from './addAutomationPrompt';

const TABLE_HEAD = ["Label", "Channel", "Status", "Automation", ""];

const PromptPage = () => {
  const [query, setQuery] = useState<string>("");
  const [channelType, setChannelType] = useState<Channel>(Channel.AllChannels);
  const [promptData, setPromptData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedPrompt, setSelectedPrompt] = useState<any>(null);
  const [resData, setResData] = useState<any>(null);

  const {
    data,
    error,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    isFetched,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    refetch,
  } = useInfinitePrompts(channelType, query, false, 10);

  const debouncedRefetch = useCallback(
    _.debounce(() => {
      refetch();
    }, 300),
    [refetch]
  );

  useEffect(() => {
    setCurrentPage(1);
    debouncedRefetch();
    // clean-up function to cancel debounce on unmount
    return () => {
      debouncedRefetch.cancel();
    };
  }, [channelType, query, debouncedRefetch]);

  useEffect(() => {
    if (data) {
      const totalItems = data.pages[0].total;
      const totalPages = Math.ceil(totalItems / 10);
      setTotalPages(totalPages);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const pageData = data.pages[currentPage - 1]?.data || [];
      setPromptData(pageData); // Set only the data of the current page
    }
  }, [data, currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
      // Call fetchNextPage only if the last page has not been reached.
      if (hasNextPage && currentPage + 1 <= totalPages) {
        fetchNextPage();
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      if (hasPreviousPage) {
        fetchPreviousPage();
      }
    }
  };

  // Edit State
  const [openEditPage, setOpenEditPage] = React.useState(false);
  const handleOpenEditPage = () => {
    refetch();
    setOpenEditPage(!openEditPage);
  }
  // Add State
  const [openAddPage, setOpenAddPage] = React.useState(false);
  const handleOpenAddPage = () => {
    refetch();
    setOpenAddPage(!openAddPage);
  }
  // Run State
  const [openRunPage, setOpenRunPage] = React.useState(false);
  const handleOpenRunPage = () => {
    setOpenRunPage(!openRunPage);
  }
  // Response State
  const [openResponsePage, setOpenResponsePage] = React.useState(false);
  const handleOpenResponsePage = () => {
    setOpenResponsePage(!openResponsePage);
  }

  // Add Automation State
  const [openAddAutomationPage, setOpenAddAutomationPage] = React.useState(false);
  const handleOpenAddAutomationPage = () => {
    setOpenAddAutomationPage(!openAddAutomationPage);
  }

  return (
    <div className="mx-auto max-w-5xl relative h-full text-black flex flex-col pb-2 pt-10">
      <Card placeholder className="h-full w-full">
        <div className="rounded-none p-4">
          <p className='text-lg font-semibold mb-4'>All Prompts</p>
          <div className='flex items-center justify-between'>
            <div className='flex items-center flex-1 gap-4'>
              <div className='w-2/5'>
                <SearchInput
                  label={'Search'}
                  type="text"
                  value={query}
                  color="black"
                  onChangeHandler={(e) => {
                    setQuery(e.target.value);
                  }}
                />
              </div>
              <ChannelSelect channelType={channelType} setChannelType={setChannelType} isBorder />
            </div>
            <Button placeholder className="flex items-center gap-2 rounded-md h-[38px]" size="sm" variant='outlined' onClick={handleOpenAddPage}>
              <LuPlus size={16} />
              <p className="text-xs font-normal capitalize">Add prompt</p>
            </Button>
            <Button placeholder className="flex items-center gap-2 rounded-md h-[38px] ml-3" size="sm" variant='outlined' onClick={handleOpenAddAutomationPage}>
              <LuPlus size={16} />
              <p className="text-xs font-normal capitalize">Add an Automation Prompt</p>
            </Button>
          </div>
        </div>
        <CardBody placeholder className="overflow-auto px-4 py-0">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={head}
                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  >
                    <Typography placeholder
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                    >
                      {head}{" "}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {promptData.map(
                (prompt: any, index: number) => {
                  const isLast = index === promptData.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";

                  return (
                    <PromptTableRow key={index} prompt={prompt} classes={classes}
                      onDelete={refetch}
                      onEdit={()=>{
                        setSelectedPrompt(prompt);
                        handleOpenEditPage();
                      }}
                      onRun={()=>{
                        setSelectedPrompt(prompt);
                        handleOpenRunPage();
                      }}
                    />
                  );
                },
              )}
            </tbody>
          </table>
        </CardBody>
        <CardFooter placeholder className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography placeholder variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPages}
          </Typography>
          <div className="flex gap-2">
            {isFetched && promptData.length > 0 &&
              <>
                <Button placeholder variant="outlined" size="sm" onClick={handlePreviousPage} disabled={currentPage === 1 || isFetchingPreviousPage}>
                  Previous
                </Button>
                <Button placeholder variant="outlined" size="sm" onClick={handleNextPage} disabled={currentPage === totalPages || isFetchingNextPage}>
                  Next
                </Button>
              </>}
          </div>
        </CardFooter>
      </Card>
      <EditPrompt open={openEditPage} handleOpen={handleOpenEditPage} promptData={selectedPrompt}/>
      <AddPrompt open={openAddPage} handleOpen={handleOpenAddPage} />
      <AddAutomationPrompt open={openAddAutomationPage} handleOpen={handleOpenAddAutomationPage} />
      <RunPrompt open={openRunPage} handleOpen={handleOpenRunPage} prompt={selectedPrompt} onRun={(res)=> {
        setResData(res);
        handleOpenResponsePage();
      }}/>
      {resData && <ResponseResultPrompt open={openResponsePage} handleOpen={handleOpenResponsePage} data={resData}/>}
    </div>
  )
}

export default PromptPage