import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import { getToken, removeToken } from "./storageHelper";
import { FormImage } from "@/types/basic";

class HttpClient {
  httpClient: AxiosInstance;

  constructor(baseURL: string) {
    const httpClient = axios.create({
      baseURL,
    });
    this.httpClient = httpClient;
  }

  post(apiEndpoint: string, params: any, config?: any) {
    return this.httpClient.post(apiEndpoint, params, config);
  }

  get(apiEndpoint: string, params: any = {}) {
    return this.httpClient.get(apiEndpoint, { params });
  }

  async getWithToken(apiEndpoint: string, params: any = {}) {
    this.httpClient.interceptors.request.use(async (request) => {
      const token = await getToken();
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token}`,
      } as AxiosRequestHeaders;

      return request;
    });

    try {
      const response = await this.httpClient.get(apiEndpoint, { params });
      return response;
    } catch (error: any) {
      if (error.response.status === 403) {
        removeToken();

        return {
          data: {
            success: false,
            code: 403,
            data: null,
          },
        };
      }
    }
  }

  async postWithToken(apiEndpoint: string, params = {}, config = {}) {
    this.httpClient.interceptors.request.use(async (request) => {
      const token = await getToken();

      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token}`,
      } as AxiosRequestHeaders;

      return request;
    });

    try {
      const response = await this.httpClient.post(apiEndpoint, params, config);
      return response;
    } catch (error: any) {
      if (error.response.status === 403) {
        removeToken();

        return {
          data: {
            success: false,
            code: 403,
            data: null,
          },
        };
      }
    }
  }

  async uploadImageWithToken(
    apiEndpoint: string,
    params: FormImage,
    additionalParams: any = {}
  ) {
    const formData = new FormData();
    formData.append(`${params.key}`, params.imageFile);

    // Append any additional parameters to the form data
    for (const key in additionalParams) {
      if (Object.prototype.hasOwnProperty.call(additionalParams, key)) {
        formData.append(key, additionalParams[key]);
      }
    }

    this.httpClient.interceptors.request.use(async (request) => {
      const token = await getToken();
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      } as AxiosRequestHeaders;

      return request;
    });

    try {
      const response = await this.httpClient.post(apiEndpoint, formData);
      return response;
    } catch (error: any) {
      if (
        (error.response && error.response.status === 403) ||
        error.response.status === 500
      ) {
        removeToken();

        return {
          data: {
            success: false,
            code: 403,
            data: null,
          },
        };
      }
      throw error;
    }
  }
}

export default new HttpClient(
  process.env.REACT_APP_SERVER_URL ?? "https://localhost:8080"
);
